import { Type } from '@angular/core'
import { HomeComponent } from '@pages/home/home.component'
import { AboutUsComponent } from '@pages/about-us/about-us.component'
import { TimetableComponent } from '@pages/timetable/timetable.component'
import { ContactComponent } from '@pages/contact/contact.component'

export enum Lang {
  PL = 'pl',
  EN = 'en'
}

export const DEFAULT_LANGUAGE = Lang.PL

type RouteDataType = {
  [lang in Lang]: {
    path: string
    title: string
  }
}

type RouteListType = {
  [index: string]: RouteDataType
}

type FullRouteListType = {
  [index: string]: RouteDataType & {
    component: Type<any>
  }
}

export const ROUTES_DATA: RouteListType = {
  home: {
    [Lang.PL]: {
      path: '',
      title: 'Home'
    },
    [Lang.EN]: {
      path: 'en',
      title: 'Home'
    }
  },
  aboutUs: {
    [Lang.PL]: {
      path: 'o-nas',
      title: 'O nas'
    },
    [Lang.EN]: {
      path: 'about-us',
      title: 'About Us'
    }
  },
  timetable: {
    [Lang.PL]: {
      path: 'grafik',
      title: 'Grafik zajęć'
    },
    [Lang.EN]: {
      path: 'timetable',
      title: 'Timetable'
    }
  },
  contact: {
    [Lang.PL]: {
      path: 'kontakt',
      title: 'Kontakt'
    },
    [Lang.EN]: {
      path: 'contact',
      title: 'Contact'
    }
  }
}

export const ROUTES: FullRouteListType = {
  home: {
    ...ROUTES_DATA['home'],
    component: HomeComponent
  },
  aboutUs: {
    ...ROUTES_DATA['aboutUs'],
    component: AboutUsComponent
  },
  timetable: {
    ...ROUTES_DATA['timetable'],
    component: TimetableComponent
  },
  contact: {
    ...ROUTES_DATA['contact'],
    component: ContactComponent
  }
}
