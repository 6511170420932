<header class="flex justify-content-between align-items-center surface-ground px-3 py-2 border-bottom-1 surface-border">
  <div class="flex align-items-center gap-2 lg:gap-3">
    @if ((isBreakpointsLoaded$ | async) && !(isLg$ | async)) {
      <p-button
        type="button"
        size="large"
        severity="primary"
        [text]="true"
        icon="pi pi-bars"
        (click)="toggleMobileMenu()"
      />
    }

    <a [routerLink]="homeUrl" class="line-height-0 text-color no-underline flex align-items-center gap-2">
      <img ngSrc="assets/logo.svg" alt="Logo" width="45" height="45" />
      <span class="line-height-1 font-semibold text-sm">
        <span class="text-lg font-bold">KSW</span><br>
        SZCZECINEK
      </span>
    </a>

    @if (isLg$ | async) {
      <nav>
        <ul class="list-none flex gap-2 p-0 m-0">
          @for (item of itemsLeft; track item.label) {
            <li>
              <a
                pRipple
                class="p-button p-component p-button-link no-underline flex gap-2 align-items-center text-color-secondary"
                [routerLink]="item.routerLink"
                routerLinkActive="surface-200"
                [routerLinkActiveOptions]="{ exact: ['', '/'].includes(item.routerLink) }"
              >
                @if (item.icon) {
                  <i [class]="item.icon"></i>
                }
                <span class="p-button-label">{{ item.label }}</span>
              </a>
            </li>
          }
        </ul>
      </nav>
    }
  </div>

  @if (isSm$ | async) {
    <div class="flex align-items-center gap-2 p-0 m-0">
      <p-button
        severity="warning"
        icon="pi pi-user-plus"
        [label]="(isMd$ | async) ? ('header.register' | translate) : ''"
        (click)="openAuthModal('register')"
      />
      <p-button
        icon="pi pi-sign-in"
        [label]="(isMd$ | async) ? ('header.login' | translate) : ''"
        (click)="openAuthModal('login')"
      />
    </div>
  }

</header>
<p-sidebar [(visible)]="mobileMenuVisible" styleClass="border-top-0">
  <ng-template pTemplate="headless">
    <div class="flex flex-column h-full">
      <div class="flex align-items-center justify-content-between px-3 py-2 border-bottom-1 surface-border surface-ground">
        <a [routerLink]="homeUrl" class="line-height-0 text-color no-underline flex align-items-center gap-2">
          <img ngSrc="assets/logo.svg" alt="Logo" width="45" height="45" />
          <span class="line-height-1 font-semibold text-sm">
            <span class="text-lg font-bold">KSW</span><br>
            SZCZECINEK
          </span>
        </a>
        <p-button
          type="button"
          size="large"
          severity="primary"
          [text]="true"
          icon="pi pi-times"
          (onClick)="toggleMobileMenu()"
        />
      </div>

      <div class="overflow-y-auto">
        <nav>
          <ul class="list-none p-0 m-0 overflow-hidden">
            @for (item of itemsLeft; track item.label) {
              <li>
                <a
                  pRipple
                  class="flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 transition-duration-150 transition-colors no-underline"
                  [routerLink]="item.routerLink"
                  routerLinkActive="surface-200 hover:surface-200"
                  [routerLinkActiveOptions]="{ exact: ['', '/'].includes(item.routerLink) }"
                >
                  @if (item.icon) {
                    <i class="mr-2" [class]="item.icon"></i>
                  }
                  <span class="font-medium">{{ item.label }}</span>
                </a>
              </li>
            }
          </ul>
        </nav>
      </div>

      <div class="flex flex-column align-items-stretch gap-2 mt-auto p-3 border-top-1 surface-border surface-ground">
        <p-button
          severity="warning"
          styleClass="w-full"
          icon="pi pi-user-plus"
          [label]="'header.register' | translate"
          (click)="openAuthModal('register')"
        />
        <p-button
          styleClass="w-full"
          icon="pi pi-sign-in"
          [label]="'header.login' | translate"
          (click)="openAuthModal('login')"
        />
      </div>
    </div>
  </ng-template>
</p-sidebar>
