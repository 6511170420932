import { Component, Input } from '@angular/core'
import { Lang } from '@core/constants/routes.constants'
import { NgOptimizedImage } from '@angular/common'
import { Button, ButtonDirective } from 'primeng/button'
import { Ripple } from 'primeng/ripple'
import { RouterLinkActive } from '@angular/router'

type FooterItemType = {
  label: string
  href: string
}

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    NgOptimizedImage,
    Button,
    Ripple,
    ButtonDirective,
    RouterLinkActive
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  @Input({ required: true }) lang!: Lang

  readonly currentYear = new Date().getFullYear()

  readonly contact = {
    tel: {
      label: '+48 889 233 159',
      href: 'tel:+48889233159'
    },
    email: {
      label: 'kswszczecinek.1991@gmail.com',
      href: 'mailto:kswszczecinek.1991@gmail.com'
    },
    map: 'https://www.google.com/maps/place//data=!4m2!3m1!1s0x47018526b8ad4f5b:0x59bf3c447f6cbf32?sa=X&ved=1t:8290&hl=pl-PL&ictx=111'
  }

  readonly social = {
    facebook: 'https://www.facebook.com/ksw.zsz',
    instagram: 'https://www.instagram.com/ksw.szczecinek/',
    whatsapp: 'https://wa.link/zz57nu'
  }

  readonly regulations: FooterItemType[] = [
    {
      label: 'Regulamin KSW Szczecinek',
      href: '/assets/docs/regulamin-ksw-szczecinek.pdf'
    },
    {
      label: 'Regulamin KSW Biały Bór',
      href: '/assets/docs/regulamin-ksw-szczecinek-bialy-bor.pdf'
    },
    {
      label: 'Regulamin finansowy',
      href: '/assets/docs/regulamin-finasnowy-ksw-szczecinek.pdf'
    }
  ]

  readonly docs: FooterItemType[] = [
    {
      label: 'Deklaracja KSW Szczecinek',
      href: '/assets/docs/deklaracja-ksw.pdf'
    },
    {
      label: 'Deklaracja KSW Biały Bór',
      href: '/assets/docs/deklaracja-ksw-bialy-bor.pdf'
    },
    {
      label: 'Karta zdrowia',
      href: '/assets/docs/karta-zdrowia.pdf'
    }
  ]
}
