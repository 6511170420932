<div class="w-full bg-primary-500 text-50">
  <div class="p-container py-4">
    <div class="flex gap-4 flex-column lg:flex-row">

      <div class="flex flex-column sm:flex-row lg:flex-column gap-2">
        <img ngSrc="assets/img/pajewski-team.webp" alt="Pajewski Team" width="200" height="75" class="h-auto lg:mt-1 mx-auto sm:mx-0" />
        <div class="flex flex-column gap-2 mx-auto sm:mr-0 sm:ml-auto lg:ml-0 lg:mt-auto">
          <h5 class="mt-0 lg:mt-auto mb-0 text-400 text-center sm:text-left">Znajdź nas na:</h5>
          <div class="flex gap-3 mb-1">
            <a pRipple [href]="social.facebook" class="no-underline text-50 hover:text-orange-400 transition-duration-200" target="_blank">
              <i class="pi pi-facebook text-4xl"></i>
            </a>
            <a pRipple [href]="social.instagram" class="no-underline text-50 hover:text-orange-400 transition-duration-200" target="_blank">
              <i class="pi pi-instagram text-4xl"></i>
            </a>
            <a pRipple [href]="social.whatsapp" class="no-underline text-50 hover:text-orange-400 transition-duration-200" target="_blank">
              <i class="pi pi-whatsapp text-4xl"></i>
            </a>
          </div>
        </div>
      </div>

      <div class="border-bottom-1 lg:border-bottom-0 lg:border-left-1 border-600"></div>

      <div class="w-full">
        <div class="flex flex-column sm:flex-row gap-4">
          <div class="flex flex-column">
            <div class="flex gap-3 md:gap-6 flex-column md:flex-row">
              <div>
                <h2 class="mt-0 mb-3 text-lg text-400">Regulaminy</h2>
                <nav>
                  <ul class="list-none m-0 p-0 text-md">
                    @for (item of regulations; track item.label) {
                      <li class="my-2">
                        <a [href]="item.href" class="text-50 no-underline hover:underline" target="_blank">
                          {{ item.label }}
                        </a>
                      </li>
                    }
                  </ul>
                </nav>
              </div>

              <div>
                <h2 class="mt-0 mb-3 text-lg text-400">Do pobrania</h2>
                <nav>
                  <ul class="list-none m-0 p-0 text-md">
                    @for (item of docs; track item.label) {
                      <li class="my-2">
                        <a [href]="item.href" class="text-50 no-underline hover:underline" target="_blank">
                          {{ item.label }}
                        </a>
                      </li>
                    }
                  </ul>
                </nav>
              </div>
            </div>
            <div class="pt-3 mt-auto">
              <h2 class="mt-0 mb-3 text-lg text-400">Pobierz aplikacje:</h2>
              <div class="flex gap-2">
                <a href="#">
                  <img ngSrc="assets/img/google-play-white.webp" alt="KSW Szczecinek - Android App" width="116" height="35" class="h-auto" />
                </a>
                <a href="#">
                  <img ngSrc="assets/img/app-store-white.webp" alt="KSW Szczecinek - iOS App" width="116" height="35" class="h-auto" />
                </a>
              </div>
            </div>
          </div>

          <div class="sm:ml-auto">
            <h2 class="mt-0 mb-3 text-lg text-400">Masz pytania?</h2>
            <div>
              <a class="flex gap-2 align-items-center no-underline mb-3 text-50 hover:text-orange-400 transition-duration-200" [href]="contact.tel.href">
                <i class="pi pi-phone border-round-lg border-1 p-2 text-orange-400"></i>
                <span>{{ contact.tel.label }}</span>
              </a>
              <a class="flex gap-2 align-items-center no-underline mb-3 text-50 hover:text-orange-400 transition-duration-200" [href]="contact.email.href">
                <i class="pi pi-at border-round-lg border-1 p-2 text-orange-400"></i>
                <span>{{ contact.email.label }}</span>
              </a>
            </div>

            <hr class="my-4 border-600" />

            <h2 class="mt-0 mb-3 text-lg text-400">Gdzie jesteśmy?</h2>
            <address class="mb-3">
              Wiatraczna 5,<br />Szczecinek,<br />Poland, 78-400
            </address>
            <a
              pRipple
              class="p-button p-component no-underline flex gap-2 align-items-center justify-content-center p-button-warning"
              [href]="contact.map"
              target="_blank"
            >
              <i class="pi pi-map-marker"></i>
              <span>Zobacz na mapie</span>
            </a>
          </div>
        </div>
      </div>

    </div>
    <hr class="my-4 border-600" />
    <p class="m-0 text-sm">© {{ currentYear }} KSW Szczecinek</p>
  </div>
</div>
