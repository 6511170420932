<app-default-layout page="contact">
  <div class="contact-form">
    <h2>Contact Us</h2>
    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
      <div class="p-field">
        <label for="title">Title</label>
        <input id="title" type="text" pInputText formControlName="title" />
        <app-error-messages [control]="contactForm.get('title')"></app-error-messages>
      </div>

      <div class="p-field">
        <label for="name">Name</label>
        <input id="name" type="text" pInputText formControlName="name" />
        <app-error-messages [control]="contactForm.get('name')"></app-error-messages>
      </div>

      <div class="p-field">
        <label for="email">E-mail</label>
        <input id="email" type="email" pInputText formControlName="email" />
        <app-error-messages [control]="contactForm.get('email')"></app-error-messages>
      </div>

      <div class="p-field">
        <label for="phone">Phone Number</label>
        <p-inputMask id="phone" [mask]="PHONE_NUMBER_FIELD.mask" formControlName="phone" [placeholder]="PHONE_NUMBER_FIELD.placeholder"/>
        <app-error-messages [control]="contactForm.get('phone')"></app-error-messages>
      </div>

      <div class="p-field">
        <label for="message">Message</label>
        <textarea id="message" rows="5" pInputTextarea formControlName="message"></textarea>
        <app-error-messages [control]="contactForm.get('message')"></app-error-messages>
      </div>

      <p-button
        type="submit"
        label="Send Message"
        class="p-mt-3"
        [disabled]="contactForm.invalid"
      />
    </form>
    <p-messages></p-messages>
  </div>

</app-default-layout>
