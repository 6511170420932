import { Component } from '@angular/core'
import { DefaultComponent } from '@layouts/default/default.component'

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [DefaultComponent],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss'
})
export class AboutUsComponent {}
