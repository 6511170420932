import { Component } from '@angular/core'
import { DefaultComponent } from '@layouts/default/default.component'
import { TableModule } from 'primeng/table';
import { ScheduleComponent } from '@features/schedule/schedule.component';

@Component({
  selector: 'app-timetable',
  standalone: true,
  imports: [DefaultComponent, TableModule, ScheduleComponent],
  templateUrl: './timetable.component.html',
  styleUrl: './timetable.component.scss'
})
export class TimetableComponent {


  // protected readonly timetable = [
  //   ['9:00 - 10:00', '', '', '', '', '', 'Zajęcia indywidualne', ''],
  //   ['10:00 - 11:30', '', '', '', '', '', 'Kickboxing', ''],
  //   ['11:30 - 12:30', '', '', '', '', '', 'Zajęcia indywidualne', ''],
  //   ['16:00 - 16:45', '', 'Taekwondo (4-6 lat)', '', 'Taekwondo (4-6 lat)', '', '', ''],
  //   ['16:40 - 17:40', 'Zajęcia indywidualne', '', 'Zajęcia indywidualne', '', 'Zajęcia indywidualne', '', ''],
  //   ['17:00 - 18:00', '', 'Taekwondo (7-11 lat)', '', 'Taekwondo (7-11 lat)', '', '', ''],
  //   ['17:45 - 18:45', 'Taekwondo / Kickboxing (12-15 lat)', '', 'Taekwondo / Kickboxing (12-15 lat)', '', 'Grupa zawodnicza']
  // ]
}
