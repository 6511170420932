import { Injectable } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { environment } from '@environments/environment'

const MOCK_API_RESPONSE_TYPE = {
  home: {
    title: 'Home',
    description: 'Home page description.'
  },
  aboutUs: {
    title: 'About',
    description: 'About page description.'
  },
  contact: {
    title: 'Contact',
    description: 'Contact page description.'
  },
  timetable: {
    title: 'Timetable',
    description: 'Timetable page description.'
  }
}

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(
    private meta: Meta,
    private titleService: Title
  ) {}

  setMetaTags(page?: string): void {
    this._addRobots()

    // @ts-ignore
    if (page && MOCK_API_RESPONSE_TYPE[page]) {
      // @ts-ignore
      const data = MOCK_API_RESPONSE_TYPE[page]

      this.titleService.setTitle(`${data.title} | ${environment.siteName}`)
      this.meta.updateTag({ name: 'description', content: data.description })
    } else {
      this.titleService.setTitle(environment.siteName)
      this.meta.updateTag({ name: 'description', content: '' })
    }
  }

  private _addRobots() {
    if (!environment.production) {
      this.meta.addTag({ name: 'robots', content: 'noindex, nofollow' })
    }
  }
}
