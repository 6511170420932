import { Component, Input, OnInit } from '@angular/core'
import { HeaderComponent } from '@features/header/header.component'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { ActivatedRoute } from '@angular/router'
import { SeoService } from '@core/services/seo/seo.service'
import { DEFAULT_LANGUAGE } from '@core/constants/routes.constants'
import { FooterComponent } from '@features/footer/footer.component'

@Component({
  selector: 'app-default-layout',
  standalone: true,
  imports: [HeaderComponent, TranslateModule, FooterComponent],
  templateUrl: './default.component.html'
})
export class DefaultComponent implements OnInit {
  currentLang = DEFAULT_LANGUAGE

  @Input({ required: true }) page!: string

  constructor(
    private seoService: SeoService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {
    if (this.activatedRoute.snapshot.data['lang']) {
      this.currentLang = this.activatedRoute.snapshot.data['lang']

      this.translate.use(this.currentLang)
    }
  }

  ngOnInit() {
    this.seoService.setMetaTags(this.page)
  }
}
