import { Route, Routes } from '@angular/router'
import { Lang, ROUTES } from '@core/constants/routes.constants'

const generateRoute = (name: string): Route[] =>
  ROUTES[name]
    ? [
        {
          path: ROUTES[name].pl.path,
          component: ROUTES[name].component
        },
        {
          data: { lang: Lang.EN },
          path: ROUTES[name].en.path,
          component: ROUTES[name].component
        }
      ]
    : []

export const routes: Routes = [
  ...generateRoute('home'),
  ...generateRoute('aboutUs'),
  ...generateRoute('timetable'),
  ...generateRoute('contact')
]
