import { inject, Injectable } from '@angular/core'
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout'
import { Observable, map, BehaviorSubject } from 'rxjs'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

enum MediaQueries {
  XS = '(max-width: 575.9px)',
  SM = '(min-width: 576px)',
  MD = '(min-width: 768px)',
  LG = '(min-width: 992px)',
  XL = '(min-width: 1200px)'
}

@Injectable({
  providedIn: 'root'
})
export class BreakpointService {
  private breakpointObserver = inject(BreakpointObserver)

  private isLoadedSubject = new BehaviorSubject<boolean>(false)
  private isXsSubject = new BehaviorSubject<boolean>(false)
  private isSmSubject = new BehaviorSubject<boolean>(false)
  private isMdSubject = new BehaviorSubject<boolean>(false)
  private isLgSubject = new BehaviorSubject<boolean>(false)
  private isXlSubject = new BehaviorSubject<boolean>(false)

  isLoaded$: Observable<boolean> = this.isLoadedSubject.asObservable()
  isXs$: Observable<boolean> = this.isXsSubject.asObservable()
  isSm$: Observable<boolean> = this.isSmSubject.asObservable()
  isMd$: Observable<boolean> = this.isMdSubject.asObservable()
  isLg$: Observable<boolean> = this.isLgSubject.asObservable()
  isXl$: Observable<boolean> = this.isXlSubject.asObservable()

  constructor() {
    this.breakpointObserver
      .observe([
        MediaQueries.XS,
        MediaQueries.SM,
        MediaQueries.MD,
        MediaQueries.LG,
        MediaQueries.XL
      ])
      .pipe(
        map(({ breakpoints }: BreakpointState) => breakpoints),
        takeUntilDestroyed()
      )
      .subscribe((breakpoints) => {
        this.isXsSubject.next(breakpoints[MediaQueries.XS])
        this.isSmSubject.next(breakpoints[MediaQueries.SM])
        this.isMdSubject.next(breakpoints[MediaQueries.MD])
        this.isLgSubject.next(breakpoints[MediaQueries.LG])
        this.isXlSubject.next(breakpoints[MediaQueries.XL])

        this.isLoadedSubject.next(Object.values(breakpoints).includes(true))
      })
  }
}
