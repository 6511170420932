import { inject, Injectable } from '@angular/core'
import { AbstractControl, ValidationErrors } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { Observable } from 'rxjs'

type ErrorMessageFunctionType = (error: ValidationErrors) => Observable<string>

type ErrorMessageType = {
  [key: string]: Observable<string> | ErrorMessageFunctionType
}

@Injectable({
  providedIn: 'root'
})
export class ErrorMessagesService {
  private translateService = inject(TranslateService)

  private errorMessages: ErrorMessageType = {
    required: this.translateService.get('error.required'),
    email: this.translateService.get('error.email'),
    pattern: this.translateService.get('error.pattern'),
    minlength: ({ requiredLength }: ValidationErrors) =>
      this.translateService.get('error.minLength', { requiredLength }),
    maxlength: ({ requiredLength }: ValidationErrors) =>
      this.translateService.get('error.maxLength', { requiredLength })
  }

  getErrorMessage(control: AbstractControl): Observable<string> | null {
    if (control.errors) {
      for (const key in control.errors) {
        if (this.errorMessages[key]) {
          const error = control.errors[key]
          return typeof this.errorMessages[key] === 'function'
            ? this.errorMessages[key](error)
            : this.errorMessages[key]
        }
      }
    }
    return null
  }
}
