import { Component, Input } from '@angular/core'
import { AsyncPipe, NgOptimizedImage } from '@angular/common'
import { Router, RouterLink, RouterLinkActive } from '@angular/router'
import { Button } from 'primeng/button'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { BreakpointService } from '@core/services/breakpoint/breakpoint.service'
import { SidebarModule } from 'primeng/sidebar'
import { Ripple } from 'primeng/ripple'
import {
  DEFAULT_LANGUAGE,
  type Lang,
  ROUTES_DATA
} from '@core/constants/routes.constants'
import { TranslateModule } from '@ngx-translate/core'

type HeaderItemType = {
  label: string
  routerLink: string
  icon?: string
}

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    NgOptimizedImage,
    RouterLink,
    AsyncPipe,
    Button,
    SidebarModule,
    Ripple,
    TranslateModule,
    RouterLinkActive
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  @Input({ required: true }) lang!: Lang

  mobileMenuVisible = false

  isBreakpointsLoaded$
  isLg$
  isMd$
  isSm$

  constructor(
    private breakpointService: BreakpointService,
    private router: Router
  ) {
    this.isBreakpointsLoaded$ = this.breakpointService.isLoaded$
    this.isLg$ = this.breakpointService.isLg$
    this.isMd$ = this.breakpointService.isMd$
    this.isSm$ = this.breakpointService.isSm$

    this.breakpointService.isLg$
      .pipe(takeUntilDestroyed())
      .subscribe((isLg) => {
        if (isLg) {
          this.mobileMenuVisible = false
        }
      })

    this.router.events.pipe(takeUntilDestroyed()).subscribe(() => {
      this.mobileMenuVisible = false
    })
  }

  get homeUrl() {
    return this.lang === DEFAULT_LANGUAGE ? '/' : `/${this.lang}`
  }

  get itemsLeft(): HeaderItemType[] {
    return ['home', 'aboutUs', 'timetable', 'contact']
      .map((item) => this._loadHeaderItem(item))
      .filter((item) => item)
  }

  openAuthModal(type: string) {
    // biome-ignore lint/suspicious/noConsoleLog: <explanation>
    console.log(`open auth: ${type}`)
  }

  toggleMobileMenu() {
    this.mobileMenuVisible = !this.mobileMenuVisible
  }

  private _loadHeaderItem(item: string) {
    return (
      ROUTES_DATA[item] && {
        label: ROUTES_DATA[item][this.lang].title,
        routerLink: `/${ROUTES_DATA[item][this.lang].path}`
      }
    )
  }
}
