import { Component } from '@angular/core'
import { DefaultComponent } from '@layouts/default/default.component'

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [DefaultComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {}
