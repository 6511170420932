import { Component } from '@angular/core'
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms'
import { MessageService } from 'primeng/api'
import { InputTextModule } from 'primeng/inputtext'
import { InputNumberModule } from 'primeng/inputnumber'
import { ButtonModule } from 'primeng/button'
import { InputTextareaModule } from 'primeng/inputtextarea'
import { MessagesModule } from 'primeng/messages'
import { ErrorMessagesComponent } from '@shared/components/error-messages/error-messages.component'
import { DefaultComponent } from '@layouts/default/default.component'
import { InputMaskModule } from 'primeng/inputmask'

const PHONE_NUMBER_FIELD = {
  pattern: /^\(\d{3}\) \d{3}-\d{3}$/,
  mask: '(999) 999-999',
  placeholder: '(123) 456-789'
}

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputTextModule,
    InputNumberModule,
    ButtonModule,
    InputTextareaModule,
    MessagesModule,
    ErrorMessagesComponent,
    DefaultComponent,
    InputMaskModule
  ],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss',
  providers: [MessageService]
})
export class ContactComponent {
  readonly PHONE_NUMBER_FIELD = PHONE_NUMBER_FIELD

  contactForm = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl(null, [
      Validators.required,
      Validators.pattern(this.PHONE_NUMBER_FIELD.pattern)
    ]),
    title: new FormControl('', Validators.required),
    message: new FormControl('', Validators.required)
  })

  constructor(private messageService: MessageService) {}

  onSubmit() {
    if (this.contactForm.valid) {
      // @TODO: send contact form
      // biome-ignore lint/suspicious/noConsoleLog: <explanation>
      console.log('Form Submitted', this.contactForm.value)
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Your message has been sent.'
      })
      this.contactForm.reset()
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill out all required fields.'
      })
    }
  }
}
