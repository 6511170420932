import { Component, Input } from '@angular/core'
import { AbstractControl } from '@angular/forms'
import { ErrorMessagesService } from '@core/services/error-messages/error-messages.service'
import { Observable } from 'rxjs'
import { AsyncPipe } from '@angular/common'

@Component({
  selector: 'app-error-messages',
  standalone: true,
  templateUrl: './error-messages.component.html',
  imports: [AsyncPipe]
})
export class ErrorMessagesComponent {
  @Input() control: AbstractControl | null = null

  constructor(private errorMessagesService: ErrorMessagesService) {}

  get errorMessage(): Observable<string> | null {
    if (
      this.control?.invalid &&
      (this.control?.touched || this.control?.dirty)
    ) {
      return this.errorMessagesService.getErrorMessage(this.control)
    }

    return null
  }
}
